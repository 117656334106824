import { DOM_TRIGGER_TYPES, CUSTOM_TRIGGER_TYPES, GLOBAL_EVENT_TYPES } from '../types';

// Convert old react event types to DOM event types
const convertToDomEventType = (type: DOM_TRIGGER_TYPES): string => {
  switch (type) {
    case DOM_TRIGGER_TYPES.ON_CLICK:
      return 'click';
    case DOM_TRIGGER_TYPES.ON_MOUSE_ENTER:
      return 'mouseenter';
    case DOM_TRIGGER_TYPES.ON_MOUSE_LEAVE:
      return 'mouseleave';
    case DOM_TRIGGER_TYPES.ON_MOUSE_DOWN:
      return 'mousedown';
    case DOM_TRIGGER_TYPES.ON_MOUSE_UP:
      return 'mouseup';
    case DOM_TRIGGER_TYPES.ON_DOUBLE_CLICK:
      return 'dblclick';
    case DOM_TRIGGER_TYPES.ON_TOUCH_START:
      return 'touchstart';
    case DOM_TRIGGER_TYPES.ON_TOUCH_END:
      return 'touchend';
    case DOM_TRIGGER_TYPES.ON_ANIMATION_START:
      return 'animationstart';
    case DOM_TRIGGER_TYPES.ON_ANIMATION_END:
      return 'animationend';
  }

  return '';
};

// Check if the event type is a native DOM event
function isNativeEvent(value: string): value is DOM_TRIGGER_TYPES {
  return Object.values(DOM_TRIGGER_TYPES).includes(value as DOM_TRIGGER_TYPES);
}

// Check if the event type is a custom event
function isCustomEvent(value: string): value is CUSTOM_TRIGGER_TYPES {
  return Object.values(CUSTOM_TRIGGER_TYPES).includes(value as CUSTOM_TRIGGER_TYPES);
}

// Check if event is a tracking event
function isTrackingEvent(value: string): value is GLOBAL_EVENT_TYPES {
  return value === GLOBAL_EVENT_TYPES.TRACK;
}

function isShareEvent(value: string): value is GLOBAL_EVENT_TYPES {
  return value === GLOBAL_EVENT_TYPES.SHARE;
}

// add 'c' to id to get inner node id
function getInnerNodeId(id: string) {
  return `${id}c`;
}

// remove last 'c' from id to get outer node id
function getOuterNodeId(id: string) {
  return id.slice(0, -1);
}

export {
  convertToDomEventType,
  isNativeEvent,
  isCustomEvent,
  isTrackingEvent,
  isShareEvent,
  getInnerNodeId,
  getOuterNodeId,
};
