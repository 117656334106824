import { IContent, Variable } from 'vev';
import { VARIABLE_REGEX } from '@vev/utils';

let appliedVariables = false;

export function applyVariables(
  content: IContent['content'] | undefined,
  variables: Variable[],
): [IContent['content'], boolean] {
  appliedVariables = false;
  if (!variables || !variables?.length) return [content, appliedVariables];
  const appliedContent = applyVariablesRecursive(content, variables);
  return [appliedContent, appliedVariables];
}

function applyVariablesRecursive(value: any, variables: Variable[]): any {
  if (typeof value === 'string') {
    return value.replace(VARIABLE_REGEX, (match, key) => {
      key = key.trim();
      const variable = variables.find((v) => v.key === key);
      if (variable) {
        appliedVariables = true;
        return variable.value;
      }
      return match;
    });
  } else if (Array.isArray(value)) {
    return value.map((item) => applyVariablesRecursive(item, variables));
  } else if (typeof value === 'object' && value !== null) {
    const appliedObject: { [key: string]: any } = {};
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        const appliedValue = applyVariablesRecursive(value[key], variables);
        appliedObject[key] = appliedValue;
      }
    }
    return appliedObject;
  }
  return value;
}
